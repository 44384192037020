var map, mapMarkerCount, mapMarker = {};

$(document).ready(function() {
	if ($('#map').length && mapMarker) {
        var styles = [{
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [{"color": "#e9e9e9"}, {"lightness": 17}]
        }, {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [{"color": "#f5f5f5"}, {"lightness": 20}]
        }, {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#ffffff"}, {"lightness": 17}]
        }, {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [{"color": "#ffffff"}, {"lightness": 29}, {"weight": 0.2}]
        }, {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [{"color": "#ffffff"}, {"lightness": 18}]
        }, {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [{"color": "#ffffff"}, {"lightness": 16}]
        }, {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [{"color": "#f5f5f5"}, {"lightness": 21}]
        }, {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [{"color": "#dedede"}, {"lightness": 21}]
        }, {
            "elementType": "labels.text.stroke",
            "stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16}]
        }, {
            "elementType": "labels.text.fill",
            "stylers": [{"saturation": 36}, {"color": "#333333"}, {"lightness": 40}]
        }, {"elementType": "labels.icon", "stylers": [{"visibility": "off"}]}, {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [{"color": "#f2f2f2"}, {"lightness": 19}]
        }, {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#fefefe"}, {"lightness": 20}]
        }, {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [{"color": "#fefefe"}, {"lightness": 17}, {"weight": 1.2}]
        }];

        // Map Options
        var myOptions = {
            zoom: 12,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: false,
            scrollwheel: false,
            // Google Map Color Styles
            styles: styles,
            draggable: !($(window).width() < 923)
        };

        map = new google.maps.Map(document.getElementById('google-map'), myOptions);

        // Marker Image
        var image = '../wp-content/themes/hotelmontvalin/assets/images/marker.png';

        var globalFooterBounds = new google.maps.LatLngBounds();

        /* ========= First Marker ========= */

        // First Marker Coordination
        mapMarkerCount = mapCoordinates.length;
        for (var i = 0; i < mapMarkerCount; i++) {
            var myLatlng = new google.maps.LatLng(parseFloat(mapCoordinates[i]['y']), parseFloat(mapCoordinates[i]['x']));
            var myLatlngExtend = new google.maps.LatLng(parseFloat(mapCoordinates[i]['y']), parseFloat(mapCoordinates[i]['x']));
            var contentString = '<div class="bubbleText"><div class="titre">' + mapCoordinates[i]['title'] + '</div>' + mapCoordinates[i]['text'] + '</div>';
            mapMarker[i] = new google.maps.Marker({
                position: myLatlng,
                map: map,
                title: mapCoordinates[i]['title'],
                icon: image,
                popupTooltip: new InfoBox({
                       content: contentString,
                       boxClass: 'mapBubble'
                    ,disableAutoPan: false
                    ,maxWidth: 0
                    ,pixelOffset: new google.maps.Size(0, 0)
                    ,zIndex: null
                    ,boxStyle: {
                     }
                    ,closeBoxMargin: "0px 0px 0px 0px"
                    ,closeBoxURL: ""
                    ,infoBoxClearance: new google.maps.Size(1, 1)
                    ,isHidden: false
                    ,pane: "floatPane"
                    ,enableEventPropagation: false
                })
            });


            mapMarker[i].popupTooltip.open(map,mapMarker[i]);

            google.maps.event.addListener(mapMarker[i], 'mouseover', function () {
                //this.popupTooltip.open(map,this);
                //$('#lienVille'+this.itemId).addClass('hover');
            });

            google.maps.event.addListener(mapMarker[i], 'mouseout', function () {
                //this.popupTooltip.close();
                //$('#lienVille'+this.itemId).removeClass('hover');
            });


            var infowindow = new google.maps.InfoWindow({
                content: contentString
            });

            //google.maps.event.addListener(mapMarker[i], 'click', function() {
            //	infowindow.open(map,mapMarker[i]);
            //});
            globalFooterBounds.extend(myLatlngExtend);
        }

        if (mapMarkerCount > 0) {
            map.fitBounds(globalFooterBounds);
            setTimeout(function () {
                if (map.zoom > 13) {
                    map.setZoom(13);
                }
            }, 1000);
        }

    }
});