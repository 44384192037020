$(function() {
    $('.bloc-tuiles article').click(function(event) {
        var href = $(this).data('href');
        var target = $(this).data('target');

        if (href) {
            // if (href.indexOf('http') !== -1 ) {
            if (target == '_blank') {
                window.open(href, '_blank');
            }
            else {
                window.location.href = href;
            }
        }
    });
});