/**
 * Main
 *
 * Application principale qui sera inclue
 * dans toutes les pages du site web.
 *
 */

import './init';
import './script';
// import './fontawesome';
import './slider';
import './menu';
import './googlemaps/infobox';
import './googlemaps/googlemaps';