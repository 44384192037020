/**
 * Menu
 *
 * Tous les scripts concernant les liens
 * et boutons généraux du site web.
 *
 */

$(window).on('load', function(){
	let box = $('.header-menus')
	let button = $('.header-menus .toggle')
	let body  = $('body, html')
	let submenu = '.menu-item-has-children';

	/* bouton menu mobile */
	button.on('click', function(){
		if (box.hasClass('open')) menuClose()
		else menuOpen()
	})

	/* envenements qui ferment le menu */
	$('.scroll').on('click', function(){
		menuClose()
	})

	/* Sous menus */
    box.find(submenu+' > a').on('click', function(event){
        $(this).parent().toggleClass('open');
        event.preventDefault();
    })

	function menuOpen(){
        box.removeClass('close')
		box.removeClass('closed')
		body.addClass('noscroll')
        button.addClass('open')
		setTimeout(function(){
            box.removeClass('hidden')
            box.addClass('open')
		}, 5)
        // body.scrollTop(0);
        box.scrollTop(0);
	}

	function menuClose(){
		button.removeClass('open')
		body.removeClass('noscroll')
        box.removeClass('open')
        box.addClass('close')
        box.find(submenu).removeClass('open')
        box.one('transitionend', function(){
            box.removeClass('close')
            box.addClass('closed')
        })
	}

	document.ontouchmove = function(event){
		if($('html').hasClass('noscroll') && $( window ).width() < 1100){
			event.preventDefault()
		}
	}


	// sticky
	var sticky =  $('header');
    $(window).scroll(function() {
    	if (window.scrollY < 60) {
            if(sticky.hasClass('sticky') ){
                sticky.removeClass('sticky');
                sticky.find('div, nav').removeClass('is-sticky');
            }
		}
		else{
            if(!sticky.hasClass('sticky')){
                sticky.addClass('sticky');
                sticky.find('div, nav').addClass('is-sticky');
            }
        }
    });
})