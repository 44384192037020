import 'slick-carousel'

$(window).on('load', function(){

    /* Entête accueil */
    $('.bloc-slider').slick({
        dots: true,
        fade: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        vertical: false,
        draggable: false,
    });

});
